<template>
  <v-container class="fill-height" fluid style="min-height: 100vh;">
    <v-img :src="appConfig.images.pageNotFoundBackground" height="calc(100vh - 30px)" class="primary darken-4">
      <v-container fluid style="position:absolute; bottom:20vh;">
        <v-row align="center" justify="space-around" class="pl-15 pl-lg-0 li-text-shadow">
          <h1 class="text-h1 white--text mb-5" v-html="appConfig.texts.pageNotFound.title" />
        </v-row>
        <v-row align="center" justify="space-around" class="pl-15 pl-lg-0 li-text-shadow">
          <p class="white--text mb-10" v-html="appConfig.texts.pageNotFound.subtitle" />
        </v-row>
        <v-row align="center" justify="space-around">
          <template v-for="(btn,n) in appConfig.texts.pageNotFound.buttons">
            <v-btn :to="btn.to" :color="btn.color" :class="btn.class" :key="n" class="px-10 bg-white" x-large>
              <h3 v-text="btn.text" />
            </v-btn>
          </template>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
export default {
  title: "404 - Page not found",
  name: "PageNotFound"
};
</script>
