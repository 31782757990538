<template>
  <v-container fluid class="liProducts body-2 pa-0 grey lighten-2" style="margin-top: 64px; position: relative">
    <template v-if="mdMatrix != null && sdMatrix != null && ceMatrix != null && products != null">
      <v-sheet class="pt-3 dark px-5 bg-dark">
        <v-tabs class="bg-dark pt-3 liMdTabGroup" v-model="tab" light background-color="transparent">
          <v-tab key="0" class="liMdTabItem text-dark px-5">
            {{ tabButtons[0].label }}
          </v-tab>
          <template v-if="!mainDividerAllFilters">
            <v-tab key="1" class="liMdTabItem text-dark px-5" @click.prevent="setupEXandLPLForSdAndCe()">
              {{ tabButtons[1].label }}
            </v-tab>
            <v-tab key="2" class="liMdTabItem text-dark px-5" @click.prevent="setupEXandLPLForSdAndCe()">
              {{ tabButtons[2].label }}
            </v-tab>
          </template>
          <template v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="px-5 py-3" style="color: rgba(255, 255, 255, 0.6)" v-bind="attrs" v-on="on">{{ tabButtons[1].label }}</span>
              </template>
              <span>Geef eerst de waardes van de hoofverdeler in.</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="px-5 py-3" style="color: rgba(255, 255, 255, 0.6)" v-bind="attrs" v-on="on">{{ tabButtons[2].label }}</span>
              </template>
              <span>Geef eerst de waardes van de hoofverdeler in.</span>
            </v-tooltip>
          </template>
        </v-tabs>
      </v-sheet>
      <v-sheet class="white" style="min-height: calc(100vh - 138px); padding-bottom: 50px">
        <v-tabs-items v-model="tab">
          <!-- MD -->
          <v-tab-item>
            <v-container fluid class="row body-3">
              <!-- FILTERS -->
              <div style="width: 300px">
                <template v-for="(item, index) in md.filters">
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.model != 'ex' && item.model != 'lpl' && item.model != 'kv' && item.model != 'if'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedMD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterMdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedMD[item.model] != null" @click.prevent="clearMdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.model == 'if' && rule_NeedIntegratedFuseMD(selectedMD)">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedMD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterMdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedMD[item.model] != null" @click.prevent="clearMdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-else-if="item.model == 'kv'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedMD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterMdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedMD[item.model] != null" @click.prevent="clearMdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-else-if="item.model == 'ex'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedMD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterMdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedMD[item.model] != null" @click.prevent="clearMdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-else-if="item.model == 'lpl' && selectedMD.ex != null && selectedMD.ex != 'ex-nee'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedMD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterMdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedMD[item.model] != null" @click.prevent="clearMdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                </template>
              </div>
              <div class="d-flex" style="width: calc(100% - 300px)">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <template v-for="(header, index) in md.headers">
                          <th class="text-center" :colspan="header.colspan" :key="`${index}-headerItem`">
                            {{ header.label }}
                          </th>
                        </template>
                      </tr>
                      <tr v-if="md.headersSecondary.length > 1">
                        <template v-for="(header, index) in md.headersSecondary">
                          <th class="text-center" :colspan="header.colspan" :key="`${index}-headerItem`">
                            {{ header.label }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, itemIndex) in filteredMdItems == null ? mdMatrix : filteredMdItems" :key="itemIndex" :style="'colour' in item && item.colour != '' ? `background-color:${item.colour}` : ''">
                        <td class="text-center" v-for="(field, fieldIndex) in md.fieldOptions" :key="`${field}-${fieldIndex}-option`" style="font-size: 80%; height: 30px; min-height: 30px">
                          <template v-if="field == 'Name'">
                            <span style="min-width: 100px" class="d-block text-left">
                              {{ item[field] }}
                            </span>
                          </template>
                          <template v-else-if="field == 'Opmerkingen'">
                            <span style="min-width: 200px" class="d-block text-left">
                              {{ item[field] }}
                            </span>
                          </template>
                          <template v-else-if="field != 'Opmerkingen' && item[field] == 1">
                            <span style="max-width: 20px" class="d-block text-left">
                              <v-icon x-small> mdi-check</v-icon>
                            </span>
                          </template>
                          <template v-else-if="field == 'TN-C' || field == 'TN-S' || field == 'TT'">
                            <span style="min-width: 70px" class="d-block text-left">
                              {{ productLabel(item[field]) }}
                            </span>
                          </template>

                          <template v-else>
                            {{ item[field] }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-container>
          </v-tab-item>
          <!-- SD -->
          <v-tab-item>
            <v-container fluid class="row body-3">
              <!-- MAIN DIVIDER OPTIONS -->
              <div class="w-100 d-flex bg-light">
                <div class="ma-3" v-if="selectedMD.ex != null"><b class="mr-3">Externe bliksembeveiliging:</b> {{ selectedMD.ex.slice(3).toLowerCase() }}</div>
                <div class="ma-3" v-if="selectedMD.ex == 'ex-ja' && selectedMD.lpl != null"><b class="mr-3">LPL klasse:</b> {{ selectedMD.lpl.slice(4) }}</div>
                <div class="ma-3" v-if="selectedMD.aw != null"><b class="mr-3">Aansluitwaarde:</b>{{ selectedMD.aw.slice(3).toLowerCase() }}</div>
                <div class="ma-3" v-if="selectedMD.kv != null"><b class="mr-3">Kortsluitvastheid:</b> {{ selectedMD.kv.slice(3).toLowerCase() }}</div>
                <div class="ma-3" v-if="selectedMD.if != null"><b class="mr-3">Geïntegreerde voorzekering:</b>{{ selectedMD.if.slice(3).toLowerCase() }}</div>
              </div>
              <!-- FILTERS -->
              <div style="width: 300px">
                <template v-for="(item, index) in sd.filters">
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model == 'zn' && selectedSD.in == 'buiten' && item.model != 'kv' && item.model != 'ph'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedSD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterSdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedSD[item.model] != null" @click.prevent="clearSdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model == 'kv'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedSD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterSdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedSD[item.model] != null" @click.prevent="clearSdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                    <!-- <v-input hide-details="auto" class="liInput">
                      <template v-slot:prepend>
                        <v-checkbox dense v-model="sdKvNb" hide-details @change="checkKvSd" class="ma-0"></v-checkbox>
                      </template>
                      <label v-html="sdKvNb == false ? item.labelNB : item.label" />
                    </v-input>
                    <v-input hide-details="auto" v-if="sdKvNb == true">
                      <v-text-field v-model="sdKv" type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" @blur="checkKvSd" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedSD[item.model] != null" @click.prevent="clearSdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input> -->
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model != 'zn' && item.model != 'kv' && item.model != 'ph'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedSD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterSdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedSD[item.model] != null" @click.prevent="clearSdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <!-- PHASE -->
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model == 'ph' && sdShowPhase">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedSD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterSdItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedSD[item.model] != null" @click.prevent="clearSdFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                </template>
              </div>

              <div class="d-flex" style="width: calc(100% - 300px)">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <template v-for="(header, index) in sd.headers">
                          <th class="text-center" :colspan="header.colspan" :key="`${index}-headerItem`">
                            {{ header.label }}
                          </th>
                        </template>
                      </tr>
                      <tr v-if="sd.headersSecondary.length > 1">
                        <template v-for="(header, index) in sd.headersSecondary">
                          <th class="text-center" :colspan="header.colspan" :key="`${index}-headerItem`">
                            {{ header.label }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, itemIndex) in filteredSdItems == null ? sdMatrix : filteredSdItems" :key="itemIndex" :style="'colour' in item && item.colour != '' ? `background-color:${item.colour}` : ''">
                        <td class="text-center" v-for="(field, fieldIndex) in sd.fieldOptions" :key="`${field}-${fieldIndex}-option`" style="font-size: 80%; height: 30px; min-height: 30px">
                          <template v-if="field == 'Name'">
                            <span style="min-width: 100px" class="d-block text-left">
                              {{ item[field] }}
                            </span>
                          </template>
                          <template v-else-if="field == 'Opmerkingen'">
                            <span style="min-width: 200px" class="d-block text-left">
                              {{ item[field] }}
                            </span>
                          </template>
                          <template v-else-if="field != 'Opmerkingen' && item[field] == 1">
                            <span style="max-width: 20px" class="d-block text-left">
                              <v-icon x-small> mdi-check</v-icon>
                            </span>
                          </template>
                          <template v-else-if="field == 'TN-C' || field == 'TN-S' || field == 'TT'">
                            <span style="min-width: 70px" class="d-block text-left">
                              {{ productLabel(item[field]) }}
                            </span>
                          </template>

                          <template v-else>
                            {{ item[field] }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-container>
          </v-tab-item>
          <!-- CE -->
          <v-tab-item>
            <v-container fluid class="row body-3">
              <!-- MAIN DIVIDER OPTIONS -->
              <div class="w-100 d-flex bg-light">
                <div class="ma-3" v-if="selectedMD.ex != null"><b class="mr-3">Externe bliksembeveiliging:</b> {{ selectedMD.ex.slice(3).toLowerCase() }}</div>
                <div class="ma-3" v-if="selectedMD.ex == 'ex-ja' && selectedMD.lpl != null"><b class="mr-3">LPL klasse:</b> {{ selectedMD.lpl.slice(4) }}</div>
                <div class="ma-3" v-if="selectedMD.aw != null"><b class="mr-3">Aansluitwaarde:</b>{{ selectedMD.aw.slice(3).toLowerCase() }}</div>
                <div class="ma-3" v-if="selectedMD.kv != null"><b class="mr-3">Kortsluitvastheid:</b> {{ selectedMD.kv.slice(3).toLowerCase() }}</div>
                <div class="ma-3" v-if="selectedMD.if != null"><b class="mr-3">Geïntegreerde voorzekering:</b>{{ selectedMD.if.slice(3).toLowerCase() }}</div>
              </div>
              <!-- FILTERS  -->
              <div style="width: 300px">
                <template v-for="(item, index) in ce.filters">
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model == 'zn' && selectedSD.in == 'buiten' && item.model != 'kv' && item.model != 'ph'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedCE[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterCeItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedCE[item.model] != null" @click.prevent="clearCeFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model == 'kv'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedCE[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterCeItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedCE[item.model] != null" @click.prevent="clearCeFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                    <!-- <v-input hide-details="auto" class="liInput">
                      <template v-slot:prepend>
                        <v-checkbox dense v-model="ceKvNb" hide-details @change="checkKvCe" class="ma-0"></v-checkbox>
                      </template>
                      <label v-html="ceKvNb == false ? item.labelNB : item.label" />
                    </v-input>
                    <v-input hide-details="auto" v-if="ceKvNb == true">
                      <v-text-field v-model="ceKv" type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" @blur="checkKvCe" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedCE[item.model] != null" @click.prevent="clearCeFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  --></div>
                  <!-- PHASE -->
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model == 'ph' && ceShowPhase">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedSD[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterCeItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedSD[item.model] != null" @click.prevent="clearCeFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                  <div class="px-5 w-100 mt-3 mb-0" :key="index" v-if="item.display != false && item.model != 'zn' && item.model != 'kv' && item.model != 'ph'">
                    <label v-html="item.label" /><br />
                    <v-input hide-details="auto">
                      <v-select single-line dense v-model="selectedCE[item.model]" :items="item.options" :item-text="item.textField" :item-value="item.valueField" @change="filterCeItems" />
                      <v-btn icon x-small class="mb-5 ml-4" v-if="selectedCE[item.model] != null" @click.prevent="clearCeFilter(item.model)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-input>
                  </div>
                </template>
              </div>
              <div class="d-flex" style="width: calc(100% - 300px)">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <template v-for="(header, index) in ce.headers">
                          <th class="text-center" :colspan="header.colspan" :key="`${index}-headerItem`">
                            {{ header.label }}
                          </th>
                        </template>
                      </tr>
                      <tr v-if="ce.headersSecondary.length > 1">
                        <template v-for="(header, index) in ce.headersSecondary">
                          <th class="text-center" :colspan="header.colspan" :key="`${index}-headerItem`">
                            {{ header.label }}
                          </th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, itemIndex) in filteredCeItems == null ? ceMatrix : filteredCeItems" :key="itemIndex" :style="'colour' in item && item.colour != '' ? `background-color:${item.colour}` : ''">
                        <td class="text-center" v-for="(field, fieldIndex) in ce.fieldOptions" :key="`${field}-${fieldIndex}-option`" style="font-size: 80%; height: 30px; min-height: 30px">
                          <template v-if="field == 'Name'">
                            <span style="min-width: 100px" class="d-block text-left">
                              {{ item[field] }}
                            </span>
                          </template>
                          <template v-else-if="field == 'Opmerkingen'">
                            <span style="min-width: 250px" class="d-block text-left">
                              {{ item[field] }}
                            </span>
                          </template>
                          <template v-else-if="field != 'Opmerkingen' && item[field] == 1">
                            <span style="max-width: 20px" class="d-block text-left">
                              <v-icon x-small> mdi-check</v-icon>
                            </span>
                          </template>
                          <template v-else-if="field == 'TN-C' || field == 'TN-S' || field == 'TT'">
                            <span style="min-width: 70px" class="d-block text-left">
                              {{ productLabel(item[field]) }}
                            </span>
                          </template>

                          <template v-else>
                            {{ item[field] }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </template>
    <v-row v-else style="min-height: calc(100vh - 60px)">
      <v-progress-circular indeterminate color="primary" class="ma-auto"></v-progress-circular>
    </v-row>
  </v-container>
</template>
<script>
// import _ from "lodash";

const API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "Products",
  title: "Project",
  data() {
    return {
      defaultRules: [(v) => !!v || this.appConfig.texts.projects.required],
      apiPublicUrl: API_URL,
      tab: null,
      valid: true,
      selectedMD: {},
      filteredMdItems: null,
      md: {
        filters: [
          // 0 Externe
          {
            type: "dropdown",
            label: "Externe bliksembeveiliging?",
            model: "ex",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Ja",
                value: "ex-ja",
              },
              {
                name: "Nee",
                value: "ex-nee",
              },
              {
                name: "Niet bekend",
                value: "ex-nb",
              },
            ],
          },
          // 1 LPL
          {
            type: "dropdown",
            label: "LPL klassse",
            model: "lpl",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "I",
                value: "lpl-I",
              },
              {
                name: "II",
                value: "lpl-II",
              },
              {
                name: "III",
                value: "lpl-III",
              },
              {
                name: "IV",
                value: "lpl-IIV",
              },
              {
                name: "Niet bekend",
                value: "lpl-nb",
              },
            ],
          },
          // 2 Aansluitwaarde
          {
            type: "dropdown",
            label: "Aansluitwaarde in Ampère",
            model: "aw",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "25",
                value: "aw-25",
              },
              {
                name: "32",
                value: "aw-32",
              },
              {
                name: "40",
                value: "aw-40",
              },
              {
                name: "63",
                value: "aw-63",
              },
              {
                name: "80",
                value: "aw-80",
              },
              {
                name: "100",
                value: "aw-100",
              },
              {
                name: "125",
                value: "aw-125",
              },
              {
                name: "160",
                value: "aw-160",
              },
              {
                name: "250",
                value: "aw-250",
              },
              {
                name: "315",
                value: "aw-315",
              },
              {
                name: "400",
                value: "aw-400",
              },
              {
                name: "500",
                value: "aw-500",
              },
              {
                name: "630",
                value: "aw-630",
              },
              {
                name: "800",
                value: "aw-800",
              },
              {
                name: "1000",
                value: "aw-1000",
              },
              {
                name: "1250",
                value: "aw-1250",
              },
              {
                name: "1600",
                value: "aw-1600",
              },
              {
                name: "2000",
                value: "aw-2000",
              },
              {
                name: "2500",
                value: "aw-2500",
              },
              {
                name: "3200",
                value: "aw-3200",
              },
              {
                name: "4000",
                value: "aw-4000",
              },
              {
                name: "6300",
                value: "aw-6300",
              },
            ],
          },
          // 3 Kortsluitvastheid
          {
            type: "dropdown",
            label: "Kortsluitvastheid in kA<sub>rms</sub>",
            labelNB: "Kortsluitvastheid is <b>onbekend</b>",
            model: "kv",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "t/m 25kA",
                value: "kv-25",
              },
              {
                name: "25,1kA t/m 50kA",
                value: "kv-50",
              },
              {
                name: "50,1kA t/m 100kA",
                value: "kv-100",
              },
              {
                name: "Niet bekend",
                value: "kv-nb",
              },
            ],
          },
          // 4 Geintegreerde voorzekering
          {
            type: "dropdown",
            label: "Geïntegreerde voorzekering?",
            model: "if",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Ja",
                value: "if-ja",
              },
              {
                name: "Nee",
                value: "if-nee",
              },
            ],
          },
        ],
        headers: [
          {
            label: "Product",
            colspan: "5",
          },
          {
            label: "Externe bliksembeveiliging",
            colspan: "3",
          },
          {
            label: "LPL klasse",
            colspan: "5",
          },
          {
            label: "Aansluitwaarde",
            colspan: "22",
          },
          {
            label: "Kortsluitvastheid",
            colspan: "4",
          },
          {
            label: "Geïntegreerde voorzekering",
            colspan: "2",
          },
        ],
        headersSecondary: [
          {
            label: "Name",
            colspan: "1",
          },
          {
            label: "TN-C",
            colspan: "1",
          },
          {
            label: "TN-S",
            colspan: "1",
          },
          {
            label: "TT",
            colspan: "1",
          },
          {
            label: "Comments",
            colspan: "1",
          },
          {
            label: "Ja",
            colspan: "1",
          },
          {
            label: "Nee",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "I",
            colspan: "1",
          },
          {
            label: "II",
            colspan: "1",
          },
          {
            label: "III",
            colspan: "1",
          },
          {
            label: "IV",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "25",
            colspan: "1",
          },
          {
            label: "32",
            colspan: "1",
          },
          {
            label: "40",
            colspan: "1",
          },
          {
            label: "63",
            colspan: "1",
          },
          {
            label: "80",
            colspan: "1",
          },
          {
            label: "100",
            colspan: "1",
          },
          {
            label: "125",
            colspan: "1",
          },
          {
            label: "160",
            colspan: "1",
          },
          {
            label: "250",
            colspan: "1",
          },
          {
            label: "315",
            colspan: "1",
          },
          {
            label: "400",
            colspan: "1",
          },
          {
            label: "500",
            colspan: "1",
          },
          {
            label: "630",
            colspan: "1",
          },
          {
            label: "800",
            colspan: "1",
          },
          {
            label: "1000",
            colspan: "1",
          },
          {
            label: "1250",
            colspan: "1",
          },
          {
            label: "1600",
            colspan: "1",
          },
          {
            label: "2000",
            colspan: "1",
          },
          {
            label: "2500",
            colspan: "1",
          },
          {
            label: "3200",
            colspan: "1",
          },
          {
            label: "4000",
            colspan: "1",
          },
          {
            label: "6300",
            colspan: "1",
          },
          {
            label: "25",
            colspan: "1",
          },
          {
            label: "50",
            colspan: "1",
          },
          {
            label: "100",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "Ja",
            colspan: "1",
          },
          {
            label: "Nee",
            colspan: "1",
          },
        ],
        fieldOptions: ["Name", "TN-C", "TN-S", "TT", "Opmerkingen", "ex-ja", "ex-nee", "ex-nb", "lpl-I", "lpl-II", "lpl-III", "lpl-IV", "lpl-NB", "aw-25", "aw-32", "aw-40", "aw-63", "aw-80", "aw-100", "aw-125", "aw-160", "aw-250", "aw-315", "aw-400", "aw-500", "aw-630", "aw-800", "aw-1000", "aw-1250", "aw-1600", "aw-2000", "aw-2500", "aw-3200", "aw-4000", "aw-6300", "kv-25", "kv-50", "kv-100", "kv-nb", "if-ja", "if-nee"],
      },
      selectedSD: {},
      filteredSdItems: null,
      sdShowPhase: true,
      sd: {
        filters: [
          // 0 Aansluitwaarde
          {
            type: "dropdown",
            label: "Aansluitwaarde in Ampère",
            model: "aw",
            display: true,
            textField: "name",
            valueField: "value",
            options: [],
          },
          // 1 kortsluitvastheid
          {
            type: "dropdown",
            label: "Kortsluitvastheid in kA<sub>rms</sub>",
            labelNB: "Kortsluitvastheid is <b>onbekend</b>",
            model: "kv",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "t/m 25kA",
                value: "kv-25",
              },
              {
                name: "25,1kA t/m 50kA",
                value: "kv-50",
              },
              {
                name: "50,1kA t/m 100kA",
                value: "kv-100",
              },
              {
                name: "Niet bekend",
                value: "kv-nb",
              },
            ],
          },
          // 2 Fase
          {
            type: "dropdown",
            label: "Fase",
            display: true,
            model: "ph",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "1 Fase + Nul",
                value: "1FN",
              },
              {
                name: "3 Fase + Nul",
                value: "3FN",
              },
            ],
          },
          // 3 Geintegreerde voorzekering
          {
            type: "dropdown",
            label: "Geïntegreerde voorzekering?",
            model: "if",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Ja",
                value: "if-ja",
              },
              {
                name: "Nee",
                value: "if-nee",
              },
            ],
          },
          // 4 Binnen / Buiten
          {
            type: "dropdown",
            label: "Binnen / Buiten",
            model: "in",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Binnen",
                value: "binnen",
              },
              {
                name: "Buiten",
                value: "buiten",
              },
            ],
          },
          // 5 Zone
          {
            type: "dropdown",
            label: "Zone",
            display: true,
            model: "zn",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "lPZ0A",
                value: "lpz0a",
              },
              {
                name: "lPZ0B",
                value: "lpz0b",
              },
              {
                name: "Niet bekend",
                value: "lpznb",
              },
            ],
          },
          // 99 EXTERN Wordt gezet via MD
          {
            type: "dropdown",
            label: "Externe bliksembeveiliging?",
            model: "ex",
            display: false,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Ja",
                value: "ex-ja",
              },
              {
                name: "Nee",
                value: "ex-nee",
              },
              {
                name: "Niet bekend",
                value: "ex-nb",
              },
            ],
          },
          // 100 LPL Wordt gezet via MD
          {
            type: "dropdown",
            label: "LPL klassse",
            model: "lpl",
            display: false,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "I",
                value: "lpl-I",
              },
              {
                name: "II",
                value: "lpl-II",
              },
              {
                name: "III",
                value: "lpl-III",
              },
              {
                name: "IV",
                value: "lpl-IIV",
              },
              {
                name: "Niet bekend",
                value: "lpl-nb",
              },
            ],
          },
        ],
        headers: [
          {
            label: "Product",
            colspan: "5",
          },
          {
            label: "Externe bliksembeveiliging",
            colspan: "3",
          },
          {
            label: "LPL klasse",
            colspan: "5",
          },
          {
            label: "Aansluitwaarde",
            colspan: "21",
          },
          {
            label: "Kortsluitvastheid",
            colspan: "4",
          },

          {
            label: "Fase",
            colspan: "2",
          },
          {
            label: "Geïntegreerde voorzekering",
            colspan: "2",
          },
          {
            label: "Binnen / Buiten",
            colspan: "2",
          },
          {
            label: "Zone",
            colspan: "3",
          },
        ],
        headersSecondary: [
          {
            label: "Name",
            colspan: "1",
          },
          {
            label: "TN-C",
            colspan: "1",
          },
          {
            label: "TN-S",
            colspan: "1",
          },
          {
            label: "TT",
            colspan: "1",
          },
          {
            label: "Comments",
            colspan: "1",
          },
          {
            label: "Ja",
            colspan: "1",
          },
          {
            label: "Nee",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "I",
            colspan: "1",
          },
          {
            label: "II",
            colspan: "1",
          },
          {
            label: "III",
            colspan: "1",
          },
          {
            label: "IV",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "25",
            colspan: "1",
          },
          {
            label: "32",
            colspan: "1",
          },
          {
            label: "40",
            colspan: "1",
          },
          {
            label: "63",
            colspan: "1",
          },
          {
            label: "80",
            colspan: "1",
          },
          {
            label: "100",
            colspan: "1",
          },
          {
            label: "125",
            colspan: "1",
          },
          {
            label: "160",
            colspan: "1",
          },
          {
            label: "250",
            colspan: "1",
          },
          {
            label: "315",
            colspan: "1",
          },
          {
            label: "400",
            colspan: "1",
          },
          {
            label: "500",
            colspan: "1",
          },
          {
            label: "630",
            colspan: "1",
          },
          {
            label: "800",
            colspan: "1",
          },
          {
            label: "1000",
            colspan: "1",
          },
          {
            label: "1250",
            colspan: "1",
          },
          {
            label: "1600",
            colspan: "1",
          },
          {
            label: "2000",
            colspan: "1",
          },
          {
            label: "2500",
            colspan: "1",
          },
          {
            label: "3200",
            colspan: "1",
          },
          {
            label: "4000",
            colspan: "1",
          },
          {
            label: "25",
            colspan: "1",
          },
          {
            label: "50",
            colspan: "1",
          },
          {
            label: "100",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "1FN",
            colspan: "1",
          },
          {
            label: "3FN",
            colspan: "1",
          },
          {
            label: "Ja",
            colspan: "1",
          },
          {
            label: "Nee",
            colspan: "1",
          },
          {
            label: "Inside",
            colspan: "1",
          },
          {
            label: "Outside",
            colspan: "1",
          },
          {
            label: "LPZ0A",
            colspan: "1",
          },
          {
            label: "LPZ0B",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
        ],
        fieldOptions: ["Name", "TN-C", "TN-S", "TT", "Opmerkingen", "ex-ja", "ex-nee", "ex-nee", "lpl-I", "lpl-II", "lpl-III", "lpl-IV", "lpl-NB", "aw-25", "aw-32", "aw-40", "aw-63", "aw-80", "aw-100", "aw-125", "aw-160", "aw-250", "aw-315", "aw-400", "aw-500", "aw-630", "aw-800", "aw-1000", "aw-1250", "aw-1600", "aw-2000", "aw-2500", "aw-3200", "aw-4000", "kv-25", "kv-50", "kv-100", "kv-nb", "1FN", "3FN", "if-ja", "if-nee", "binnen", "buiten", "lpz0a", "lpz0b", "lpznb"],
      },
      selectedCE: {},
      filteredCeItems: null,
      ceShowPhase: true,
      ce: {
        filters: [
          // 0 Aansluitwaarde
          {
            type: "dropdown",
            label: "Aansluitwaarde in Ampère",
            model: "aw",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "25",
                value: "aw-25",
              },
              {
                name: "32",
                value: "aw-32",
              },
              {
                name: "40",
                value: "aw-40",
              },
              {
                name: "63",
                value: "aw-63",
              },
              {
                name: "80",
                value: "aw-80",
              },
              {
                name: "100",
                value: "aw-100",
              },
              {
                name: "125",
                value: "aw-125",
              },
              {
                name: "160",
                value: "aw-160",
              },
              {
                name: "250",
                value: "aw-250",
              },
              {
                name: "315",
                value: "aw-315",
              },
              {
                name: "400",
                value: "aw-400",
              },
              {
                name: "500",
                value: "aw-500",
              },
              {
                name: "630",
                value: "aw-630",
              },
              {
                name: "800",
                value: "aw-800",
              },
              {
                name: "1000",
                value: "aw-1000",
              },
              {
                name: "1250",
                value: "aw-1250",
              },
              {
                name: "1600",
                value: "aw-1600",
              },
              {
                name: "2000",
                value: "aw-2000",
              },
              {
                name: "2500",
                value: "aw-2500",
              },
              {
                name: "3200",
                value: "aw-3200",
              },
              {
                name: "4000",
                value: "aw-4000",
              },
            ],
          },
          // 1 Kortsluitvastheid
          {
            type: "dropdown",
            label: "Kortsluitvastheid in kA<sub>rms</sub>",
            labelNB: "Kortsluitvastheid is <b>onbekend</b>",
            model: "kv",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "t/m 6kA",
                value: "kv-6",
              },
              {
                name: "6,1 t/m 25kA",
                value: "kv-25",
              },
              {
                name: "25,1kA t/m 50kA",
                value: "kv-50",
              },
              {
                name: "50,1kA t/m 100kA",
                value: "kv-100",
              },
              {
                name: "Niet bekend",
                value: "kv-nb",
              }
            ],
          },
          // 2 Fase
          {
            type: "dropdown",
            label: "Fase",
            display: true,
            model: "ph",
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "1 Fase + Nul",
                value: "1FN",
              },
              {
                name: "3 Fase + Nul",
                value: "3FN",
              },
            ],
          },
          // 3 Geintegreerde voorzekering
          {
            type: "dropdown",
            label: "Geïntegreerde voorzekering?",
            model: "if",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Ja",
                value: "if-ja",
              },
              {
                name: "Nee",
                value: "if-nee",
              },
            ],
          },
          // 4 Binnen / Buiten
          {
            type: "dropdown",
            label: "Binnen / Buiten",
            model: "in",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Binnen",
                value: "binnen",
              },
              {
                name: "Buiten",
                value: "buiten",
              },
            ],
          },
          // 5 Zone
          {
            type: "dropdown",
            label: "Zone",
            model: "zn",
            display: true,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "lPZ0A",
                value: "lpz0a",
              },
              {
                name: "lPZ0B",
                value: "lpz0b",
              },
              {
                name: "Niet bekend",
                value: "lpznb",
              },
            ],
          },
          // 99 EXTERN Wordt gezet via MD
          {
            type: "dropdown",
            label: "Externe bliksembeveiliging?",
            model: "ex",
            display: false,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "Ja",
                value: "ex-ja",
              },
              {
                name: "Nee",
                value: "ex-nee",
              },
              {
                name: "Niet bekend",
                value: "ex-nb",
              },
            ],
          },
          // 100 LPL Wordt gezet via MD
          {
            type: "dropdown",
            label: "LPL klassse",
            model: "lpl",
            display: false,
            textField: "name",
            valueField: "value",
            options: [
              {
                name: "I",
                value: "lpl-I",
              },
              {
                name: "II",
                value: "lpl-II",
              },
              {
                name: "III",
                value: "lpl-III",
              },
              {
                name: "IV",
                value: "lpl-IIV",
              },
              {
                name: "Niet bekend",
                value: "lpl-nb",
              },
            ],
          },
        ],
        headers: [
          {
            label: "Product",
            colspan: "5",
          },
          {
            label: "Externe bliksembeveiliging",
            colspan: "3",
          },
          {
            label: "LPL klasse",
            colspan: "5",
          },
          {
            label: "Aansluitwaarde",
            colspan: "21",
          },
          {
            label: "Kortsluitvastheid",
            colspan: "5",
          },

          {
            label: "Fase",
            colspan: "2",
          },
          {
            label: "Geïntegreerde voorzekering",
            colspan: "2",
          },
          {
            label: "Binnen / Buiten",
            colspan: "2",
          },
          {
            label: "Zone",
            colspan: "3",
          },
        ],
        headersSecondary: [
          {
            label: "Name",
            colspan: "1",
          },
          {
            label: "TN-C",
            colspan: "1",
          },
          {
            label: "TN-S",
            colspan: "1",
          },
          {
            label: "TT",
            colspan: "1",
          },
          {
            label: "Comments",
            colspan: "1",
          },
          {
            label: "Ja",
            colspan: "1",
          },
          {
            label: "Nee",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "I",
            colspan: "1",
          },
          {
            label: "II",
            colspan: "1",
          },
          {
            label: "III",
            colspan: "1",
          },
          {
            label: "IV",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "25",
            colspan: "1",
          },
          {
            label: "32",
            colspan: "1",
          },
          {
            label: "40",
            colspan: "1",
          },
          {
            label: "63",
            colspan: "1",
          },
          {
            label: "80",
            colspan: "1",
          },
          {
            label: "100",
            colspan: "1",
          },
          {
            label: "125",
            colspan: "1",
          },
          {
            label: "160",
            colspan: "1",
          },
          {
            label: "250",
            colspan: "1",
          },
          {
            label: "315",
            colspan: "1",
          },
          {
            label: "400",
            colspan: "1",
          },
          {
            label: "500",
            colspan: "1",
          },
          {
            label: "630",
            colspan: "1",
          },
          {
            label: "800",
            colspan: "1",
          },
          {
            label: "1000",
            colspan: "1",
          },
          {
            label: "1250",
            colspan: "1",
          },
          {
            label: "1600",
            colspan: "1",
          },
          {
            label: "2000",
            colspan: "1",
          },
          {
            label: "2500",
            colspan: "1",
          },
          {
            label: "3200",
            colspan: "1",
          },
          {
            label: "4000",
            colspan: "1",
          },
          {
            label: "6",
            colspan: "1",
          },
          {
            label: "25",
            colspan: "1",
          },
          {
            label: "50",
            colspan: "1",
          },
          {
            label: "100",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
          {
            label: "1FN",
            colspan: "1",
          },
          {
            label: "3FN",
            colspan: "1",
          },
          {
            label: "Ja",
            colspan: "1",
          },
          {
            label: "Nee",
            colspan: "1",
          },
          {
            label: "Inside",
            colspan: "1",
          },
          {
            label: "Outside",
            colspan: "1",
          },
          {
            label: "LPZ0A",
            colspan: "1",
          },
          {
            label: "LPZ0B",
            colspan: "1",
          },
          {
            label: "Niet bekend",
            colspan: "1",
          },
        ],
        fieldOptions: ["Name", "TN-C", "TN-S", "TT", "Opmerkingen", "ex-ja", "ex-nee", "ex-nb", "lpl-I", "lpl-II", "lpl-III", "lpl-IV", "lpl-NB", "aw-25", "aw-32", "aw-40", "aw-63", "aw-80", "aw-100", "aw-125", "aw-160", "aw-250", "aw-315", "aw-400", "aw-500", "aw-630", "aw-800", "aw-1000", "aw-1250", "aw-1600", "aw-2000", "aw-2500", "aw-3200", "aw-4000", "kv-6", "kv-25", "kv-50", "kv-100", "kv-nb", "1FN", "3FN", "if-ja", "if-nee", "binnen", "buiten", "lpz0a", "lpz0b", "lpznb"],
      },
    };
  },
  computed: {
    mainDividerAllFilters() {
      if (this.selectedMD.ex != null && (this.selectedMD.ex == "ex-nee" || this.selectedMD.ex == "ex-nb") && this.selectedMD.aw != null && this.selectedMD.kv != null && this.selectedMD.kv != null && this.selectedMD.if != null) {
        return false;
      } else if (this.selectedMD.ex != null && this.selectedMD.ex == "ex-ja" && this.selectedMD.lpl != null && this.selectedMD.aw != null && this.selectedMD.kv != null && this.selectedMD.kv != null && this.selectedMD.if != null) {
        return false;
      } else {
        return true;
      }
    },
    tabButtons() {
      return this.appConfig.texts.products.tabButtons;
    },
    mdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_md`);
    },
    sdMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_sd`);
    },
    ceMatrix() {
      return this.$store.getters["liDehnProject/getCollection"](`matrix_ce`);
    },
  },
  mounted() {
    // this.getProducts();
    this.setupTitle();
    this.getMatrices();
    this.setup();
  },
  watch: {
    mainDividerAllFilters(boolean) {
      if (!boolean) {
        this.setupAwForSdAndCe();
      }
    },
  },
  methods: {
    setupEXandLPLForSdAndCe() {
      if (this.selectedMD.ex != null && this.selectedMD.ex != "") {
        this.$set(this.selectedSD, "ex", this.selectedMD.ex);
        this.$set(this.selectedSD, "lpl", this.selectedMD.lpl);
        this.$set(this.selectedCE, "ex", this.selectedMD.ex);
        this.$set(this.selectedCE, "lpl", this.selectedMD.lpl);
        this.filterSdItems();
        this.filterCeItems();
      }
    },
    setupAwForSdAndCe() {
      if (this.selectedMD.aw != null && this.selectedMD.aw != "") {
        let index = this.appConfig.texts.project.form.amp.options[`all`].findIndex((option) => option.value == this.selectedMD.aw.slice(3));
        if (index == 0) {
          index = 1;
        } else if (index == 1) {
          index = 2;
        } else {
          index = index - 1;
        }
        this.sd.filters[0].options = this.appConfig.texts.project.form.amp.options[`allFilters`].slice(0, index);
        this.ce.filters[0].options = this.appConfig.texts.project.form.amp.options[`allFilters`].slice(0, index);
      } else {
        this.sd.filters[0].options = this.appConfig.texts.project.form.amp.options[`allFilters`];
        this.ce.filters[0].options = this.appConfig.texts.project.form.amp.options[`allFilters`];
      }
    },
    setup() {
      this.md.filters.forEach((item) => {
        this.$set(this.selectedMD, item.model, null);
      });
      this.sd.filters.forEach((item) => {
        this.$set(this.selectedSD, item.model, null);
      });
      this.ce.filters.forEach((item) => {
        this.$set(this.selectedCE, item.model, null);
      });
    },
    clearMdFilter(model) {
      if (model == "ex") {
        this.selectedMD.lpl = null;
      }
      this.$set(this.selectedMD, model, null);
      this.filterMdItems();
    },

    filterMdItems() {
      if (this.selectedMD.aw != null && this.selectedMD.aw != "") {
        this.setupAwForSdAndCe();
      }
      const isEmpty = Object.values(this.selectedMD).every((x) => x === null || x === "");
      if (!isEmpty) {
        //eslint-disable-next-line
        this.filteredMdItems = this.mdMatrix.filter((item) => Object.entries(this.selectedMD).every(([k, v]) => v == "" || item[v] == undefined || item[v] == "1" || item[v] == 1));
      } else {
        this.filteredMdItems = null;
      }
      this.checkMainDivider(this.selectedMD);
    },
    clearSdFilter(model) {
      this.$set(this.selectedSD, model, null);
      this.filterSdItems();
    },
    filterSdItems() {
      const isEmpty = Object.values(this.selectedSD).every((x) => x === null || x === "");

      if (!isEmpty) {
        console.log(this.selectedSD);
        //eslint-disable-next-line
        this.filteredSdItems = this.sdMatrix.filter((item) => Object.entries(this.selectedSD).every(([k, v]) => v == "" || item[v] == undefined || item[v] == "1" || item[v] == 1));
      } else {
        this.filteredSdItems = null;
      }
      this.checkSubDivider(this.selectedSD);
    },
    clearCeFilter(model) {
      this.$set(this.selectedCE, model, null);
      this.filterCeItems();
    },
    filterCeItems() {
      const isEmpty = Object.values(this.selectedCE).every((x) => x === null || x === "");
      if (!isEmpty) {
        //eslint-disable-next-line
        this.filteredCeItems = this.ceMatrix.filter((item) => Object.entries(this.selectedCE).every(([k, v]) => v == "" || item[v] == undefined || item[v] == "1" || item[v] == 1));
      } else {
        this.filteredCeItems = null;
      }
      this.checkCe(this.selectedCE);
    },
    // COMPONENT FUNCTIONS
    setupTitle() {
      this.$title = this.appConfig.texts.products.title;
    },
    // EDIT ITEM FUNCTIONS
    product(identifier) {
      return this.products.find((item) => {
        return item.identifier == identifier;
      });
    },
    productLabel(identifier) {
      const product = this.product(identifier);
      if (product != undefined) {
        return `${product.article_number}`;
      } else {
        return "";
      }
    },
    getMatrices() {
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "li_matrix_md",
          orderBy: "sequence ASC",
          where: "deleted_at IS NULL AND status = 1",
          select: "*",
        },
        collectionName: `matrix_md`,
      });
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "li_matrix_sd",
          orderBy: "sequence ASC",
          where: "deleted_at IS NULL AND status = 1",
          select: "*",
        },
        collectionName: `matrix_sd`,
      });
      this.$store.dispatch("liDehnProject/GET_COLLECTION", {
        request: {
          object: "li_matrix_ce",
          orderBy: "sequence ASC",
          where: "deleted_at IS NULL AND status = 1",
          select: "*",
        },
        collectionName: `matrix_ce`,
      });
    },
    // RULES
    rule_NeedIntegratedFuseMD(selectedItem) {
      if (selectedItem.ex == "ex-ja") {
        if (selectedItem.lpl == null) {
          return true;
        }
        if (selectedItem.lpl == "lpl-I" || selectedItem.lpl == "lpl-II") {
          if (selectedItem.aw != null && selectedItem.aw.slice(3) <= 250) {
            selectedItem.if = "if-nee";
            return true;
          } else {
            return false;
          }
        }
        if (selectedItem.lpl == "lpl-III" || selectedItem.lpl == "lpl-IV") {
          if (selectedItem.aw != null && selectedItem.aw.slice(3) <= 160) {
            return true;
          } else {
            selectedItem.if = "if-nee";
            return false;
          }
        }
      } else {
        return true;
      }
    },
    rule_NeedLpZone(selectedItem) {
      return this.project[0].external == 1 && selectedItem.external == 1 && selectedItem.cableLength == ">10" ? true : false;
    },
    rule_NeedLocation() {
      return this.project[0].external == 1 ? true : false;
    },
    rule_ProductNotRequired(selectedItem) {
      return selectedItem.cableLength == "<10" ? true : false;
    },
    rule_NeedPhaseSd(selectedItem) {
      return Number(selectedItem.ampere) <= 40 ? true : false;
    },
    rule_SetPhase(selectedItem) {
      return selectedItem.ampere > 40 ? true : false;
    },
    searchProduct(item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.article_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },

    // CHECK FUNCTIONS
    checkMainDivider(selectedItem) {
      // console.log("checkMainDivider", this.selectedItem);
      // console.log("Aansluitwaarde = ", `aw-${selectedItem.aw.slice(3)}`);
      // console.log("Kortsluitingsvastheid = ", selectedItem.kv);
      // console.log("Integrated fuse = ", `if-${selectedItem.if.slice(3)}`);
      // console.log("EXTERNAL = ", selectedItem.ex);
      // console.log("LPL = ", selectedItem.lpl);

      // const ex = selectedItem.ex;
      // const lpl = selectedItem.lpl;

      //* SHORT CIRCUIT RESISTANCE OPTIONS *\\
      let shortCircuitResistanceOptions = [];
      if (selectedItem.aw != null && selectedItem.aw != "") {
        if (selectedItem.aw.slice(3) > 32 && selectedItem.aw.slice(3) <= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilter.options.forEach((option) => {
            if (option.value.slice(3) > 6 && option.value.slice(3) < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-6" || selectedItem.kv == "kv-100") {
            this.$set(selectedItem, "kv", "");
          }
        } else if (selectedItem.aw.slice(3) >= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilter.options.forEach((option) => {
            if (option.value.slice(3) > 6) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-6") {
            this.$set(selectedItem, "kv", "");
          }
        } else if (selectedItem.aw.slice(3) <= 32) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilter.options.forEach((option) => {
            if (option.value.slice(3) < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-100") {
            this.$set(selectedItem, "kv", "");
          }
        }
        shortCircuitResistanceOptions = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistance.notKnown];

        this.$set(this.md.filters[3], "options", shortCircuitResistanceOptions);
      } else {
        this.$set(this.md.filters[3], "options", this.appConfig.texts.project.form.shortCircuitResistanceFilter.options);
      }
    },
    checkSubDivider(selectedItem) {
      // console.log("checkSubDivider", selectedItem);
      // console.log("Aansluitwaarde = ", `aw-${selectedItem.aw.slice(3)}`);
      // console.log("Kortsluitingsvastheid = ", `kv-${selectedItem.kv.slice(3)}`);
      // console.log("Integrated fuse = ", `if-${selectedItem.if.slice(3)}`);
      // console.log("EXTERNAL = ", selectedItem.ex);
      // console.log("LPL = ", selectedItem.lpl);

      // const ex = selectedItem.ex;
      // const lpl = selectedItem.lpl;

      // console.log("EXTERNAL = ", this.project[0].external);
      // console.log("LPL = ", this.project[0].lpl);
      // const ex = this.project[0].external == 0 ? "ex-nee" : this.project[0].external == 2 ? "ex-nb" : "ex-ja";
      // const lpl = this.project[0].lpl == 0 ? "lpl-NB" : this.project[0].lpl == 1 ? "lpl-I" : this.project[0].lpl == 2 ? "lpl-II" : this.project[0].lpl == 3 ? "lpl-III" : "lpl-IV";
      // console.log("TYPE = ", selectedItem.type);
      // console.log("Aansluitwaarde = ", `aw-${selectedItem.ampere}`);
      // console.log("Kortsluitingsvastheid = ", `kv-${selectedItem.shortCircuitResistance}`);
      // console.log("Integrated fuse = ", `if-${selectedItem.preAmp}`);
      // console.log("Phase = ", selectedItem.phase);
      // console.log("GRID = ", selectedItem.grid);
      // console.log("GRID = ", selectedItem.grid);

      //* SHORT CIRCUIT RESISTANCE RULES *\\
      let shortCircuitResistanceOptions = [];
      if (selectedItem.aw != null && selectedItem.aw != "") {
        if (selectedItem.aw.slice(3) > 32 && selectedItem.aw.slice(3) <= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilter.options.forEach((option) => {
            if (option.value.slice(3) > 6 && option.value.slice(3) < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-6" || selectedItem.kv == "kv-100") {
            this.$set(selectedItem, "kv", "");
          }
        } else if (selectedItem.aw.slice(3) >= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilter.options.forEach((option) => {
            if (option.value.slice(3) > 6) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-6") {
            this.$set(selectedItem, "kv", "");
          }
        } else if (selectedItem.aw.slice(3) <= 32) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilter.options.forEach((option) => {
            if (option.value.slice(3) < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-100") {
            this.$set(selectedItem, "kv", "");
          }
        }
        this.$set(this.sd.filters[1].options, "options", [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistanceFilter.notKnown]);
        // this.sd.filters[1].options = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistanceFilter.notKnown];
      } else {
        this.$set(this.sd.filters[1].options, "options", this.appConfig.texts.project.form.shortCircuitResistanceFilter.options);
        // this.sd.filters[1].options = this.appConfig.texts.project.form.shortCircuitResistance.options;
      }
      //* PHASE RULES *\\
      if (this.rule_SetPhase_filters(selectedItem)) {
        this.$set(selectedItem, "ph", "3FN");
        this.sdShowPhase = false;
      } else {
        this.sdShowPhase = true;
      }
    },
    checkCe(selectedItem) {
      console.log("checkCe", selectedItem);
      // console.log("Aansluitwaarde = ", `aw-${selectedItem.aw.slice(3)}`);
      // console.log("Kortsluitingsvastheid = ", `kv-${selectedItem.kv.slice(3)}`);
      // console.log("Integrated fuse = ", `if-${selectedItem.if.slice(3)}`);
      // console.log("EXTERNAL = ", selectedItem.ex);
      // console.log("LPL = ", selectedItem.lpl);

      // const ex = selectedItem.ex;
      // const lpl = selectedItem.lpl;

      //* SHORT CIRCUIT RESISTANCE RULES *\\
      let shortCircuitResistanceOptions = [];
      if (selectedItem.aw != null && selectedItem.aw != "") {
        if (selectedItem.aw.slice(3) > 32 && selectedItem.aw.slice(3) <= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilterCe.options.forEach((option) => {
            if (option.value.slice(3) > 6 && option.value.slice(3) < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-6" || selectedItem.kv == "kv-100") {
            this.$set(selectedItem, "kv", "");
          }
        } else if (selectedItem.aw.slice(3) >= 800) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilterCe.options.forEach((option) => {
            if (option.value.slice(3) > 6) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-6") {
            this.$set(selectedItem, "kv", "");
          }
        } else if (selectedItem.aw.slice(3) <= 32) {
          this.appConfig.texts.project.form.shortCircuitResistanceFilterCe.options.forEach((option) => {
            if (option.value.slice(3) < 100) {
              shortCircuitResistanceOptions.push(option);
            }
          });
          if (selectedItem.kv == "kv-100") {
            this.$set(selectedItem, "kv", "");
          }
        }
        this.$set(this.ce.filters[1].options, "options", [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistanceFilter.notKnown]);
        // this.ce.filters[1].options = [...shortCircuitResistanceOptions, this.appConfig.texts.project.form.shortCircuitResistanceFilter.notKnown]
      } else {
        this.$set(this.ce.filters[1].options, "options", this.appConfig.texts.project.form.shortCircuitResistanceFilterCe.options);
        this.ce.filters[1].options = this.appConfig.texts.project.form.shortCircuitResistanceFilterCe.options;
      }
      //* PHASE RULES *\\
      if (this.rule_SetPhase_filters(selectedItem)) {
        this.$set(selectedItem, "ph", "3FN");
        this.ceShowPhase = false;
      } else {
        this.sceShowPhase = true;
      }
    },
  },
};
</script>
<style lang="scss">
.product-selector {
  .v-data-table__wrapper {
    padding-bottom: 50px;
  }
}
.liInput {
  .v-input__prepend-outer {
    margin-right: 0px;
    .v-input--selection-controls__input {
      margin-right: 0px;
    }
  }
  label {
    margin-top: 9px;
  }
}
</style>
